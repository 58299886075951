<template>
    <div class="c-user-simple">
        <img
            @click.stop="copyName(data)"
            title="复制"
            class="u-avatar"
            v-if="data?.name || !data?.wechat_name || data?.wechat_name !== '微信用户'"
            :src="avatar"
            :alt="data?.wechat_name"
        />
        <span class="u-name">{{ name }}</span>
        <template v-if="wechat">
            <span class="u-name" v-if="!data?.wechat_name || data?.wechat_name !== '微信用户'">&nbsp;({{ data?.wechat_name }})</span>
        </template>
    </div>
</template>

<script>
import { copyText, avatarUrl } from "@/utils";
import { getThumbnail, getCdnLink } from "@/utils/common";
export default {
    name: "UserSimple",
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        wechat: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        name({ data }) {
            return data?.name || data?.nickname || data?.username
        },
        avatar({data}) {
            if (data?.wechat_avatar) {
                return avatarUrl(data?.wechat_avatar)
            } else {
                const url = data?.avatar
                return url ? getThumbnail(getCdnLink(url), 64) : require("@/assets/img/common/logo.svg")
            }
        }
    },
    methods: {
        copyName(data) {
            const name = data?.wechat_name && data?.wechat_name !== "微信用户" ? data?.wechat_name : data?.name;
            copyText(name);
        },
        avatarUrl,
    },
};
</script>

<style lang="less">
.c-user-simple {
    .flex;
    align-items: center;
    gap:5px;

    .u-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .fz(15px);
        font-weight: 500;
    }
    .u-avatar {
        .size(28px);
        border-radius: 50%;
        .pointer;
        .db;
    }
}
</style>
