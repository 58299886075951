<template>
    <router-view></router-view>
</template>

<script>
import { mapMutations } from "vuex";
import { Local } from "@/utils/storage";
// import store from "@/store";
import useCount from "@/utils/count";
import User from "@/utils/user";
import {getConfByKey} from "@/service/system";
export default {
    name: "App",
    beforeCreate() {
        // store.dispatch("app/loadMyPermission");
    },
    created() {
        this.initSidebar();
        User.isLogin() && this.loadCount();
        this.loadPhone();
        this.loadPhone("express_phone_erp");
    },
    methods: {
        ...mapMutations({
            setSidebarStatus: "app/SET_SIDEBAR_STATUS",
            setState: "app/SET_STATE",
        }),
        initSidebar() {
            const sidebar = Local.get("sidebarStatus") || 1;
            if (window.innerWidth < 768) {
                this.setSidebarStatus(false);
            } else {
                this.setSidebarStatus(sidebar);
            }
        },
        async loadCount() {
            await useCount().countRepairNum();
            await useCount().countRequestNum();
            await useCount().countWorkflowNum();
        },
        async loadPhone(key = "express_phone_sys") {
            try {
                let phone = sessionStorage.getItem(key);
                if (phone) {
                    this.setState({ key, value: phone })
                    return;
                } else {
                    let phone = await getConfByKey(key).then((res) => {
                        return res.data?.data || "";
                    });
                    this.setState({ key, value: phone })
                    sessionStorage.setItem(key, phone);
                }
            } catch (e) {
                console.error(e);
            }
        }
    },
};
</script>

<style lang="less">
@import "@/assets/css/app.less";
</style>
