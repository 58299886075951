<template>
    <div class="c-create-menu">
        <el-dropdown trigger="click" :popper-options="{}">
            <span class="el-dropdown-link c-create-menu__trigger">
                <el-icon><Plus></Plus></el-icon>
                <el-icon class="el-icon--right">
                    <arrow-down />
                </el-icon>
            </span>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item>
                        <router-link :to="{ name: 'create-workflow' }" class="c-create-menu__item">
                            <el-icon><Plus/></el-icon>
                            创建工单
                        </router-link>
                    </el-dropdown-item>
                    <el-dropdown-item divided>
                        <router-link :to="{ name: 'notification-message' }" class="c-create-menu__item">
                            <el-icon><Message/></el-icon>
                            发送私信
                        </router-link>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
    </div>
</template>

<script>
export default {
    name: "CreateMenu",
};
</script>
<style lang="less">
@import "@/assets/css/views/header/create-menu.less";
</style>
