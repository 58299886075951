<template>
    <div class="c-header-user">
        <create-menu />
        <header-message />

        <el-dropdown>
            <span v-if="user" class="u-name">
                <el-avatar v-if="profile?.avatar" class="u-avatar" :src="thumbnail(profile.avatar)" :size="24">
                    <img src="@/assets/img/common/logo.svg" />
                </el-avatar>
                {{ user.username }}
            </span>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item @click="toProfile">资料设置</el-dropdown-item>
                    <el-dropdown-item @click="logout">退出</el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
    </div>
</template>

<script>
import { getCdnLink, getThumbnail } from "@/utils/common";
import User from "@/utils/user";
import HeaderMessage from "./message.vue";
import CreateMenu from "./create-menu.vue";
export default {
    name: "HeaderUser",
    components: { HeaderMessage, CreateMenu },
    data: function () {
        return {
            user: User.getInfo(),
        };
    },
    computed: {
        profile() {
            return this.$store.state.app.user?.info;
        },
    },
    methods: {
        logout() {
            User.logout();
            this.$router.push("/login");
        },
        toProfile() {
            this.$router.push({ name: "account-profile" });
        },
        thumbnail(url) {
            return getThumbnail(getCdnLink(url), 64);
        },
    },
};
</script>

<style lang="less">
.c-header-user {
    .flex;
    align-items: center;
    padding: 0 20px;
    gap: 16px;

    .u-avatar {
        margin-right: 5px;
    }

    .u-name {
        .pointer;
        color: #fff;
        display: flex;
        align-items: center;
        i {
            .mr(5px);
        }
    }
    .el-badge__content.is-fixed.is-dot {
        .rt(8px,2px);
    }
}
</style>
