<template>
    <div v-if="!item.hidden">
        <template
            v-if="
                hasOneShowingChild(item.children, item) &&
                (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
                !item.alwaysShow
            "
        >
            <el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{ 'submenu-title-noDropdown': !isNest }">
                <div class="m-nav-item" :title="onlyOneChild.meta.title">
                    <template v-if="onlyOneChild.meta.icon || (item.meta && item.meta.icon)">
                        <svg-icon :name="onlyOneChild.meta.icon || (item.meta && item.meta.icon)"></svg-icon>
                    </template>
                    <span v-if="onlyOneChild.meta.title">{{ onlyOneChild.meta.title }}</span>
                    <span class="m-nav-item__count" v-if="counts[onlyOneChild?.name]">{{ counts[onlyOneChild?.name] }}</span>
                </div>
            </el-menu-item>
        </template>

        <el-sub-menu v-else ref="subMenu" :index="resolvePath(item.path)" teleported>
            <template #title>
                <div v-if="item.meta" class="m-nav-item">
                    <template v-if="item.meta && item.meta.icon">
                        <svg-icon :name="item.meta && item.meta.icon"></svg-icon>
                    </template>
                    <span v-if="item.meta.title">{{ item.meta.title }}</span>
                    <span class="m-nav-item__count" v-if="counts[item?.name]">{{ counts[item?.name] }}</span>
                </div>
            </template>
            <nav-item
                v-for="child in item.children"
                :key="child.path"
                :is-nest="true"
                :item="child"
                :base-path="resolvePath(child.path)"
                class="nest-menu"
                :counts="counts"
            />
        </el-sub-menu>
    </div>
</template>

<script>
import path from "path";
import { isExternal } from "@/utils/validate";
export default {
    name: "SidebarItem",
    props: {
        // route object
        item: {
            type: Object,
            required: true,
        },
        isNest: {
            type: Boolean,
            default: false,
        },
        basePath: {
            type: String,
            default: "",
        },
        counts: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            onlyOneChild: null,
        };
    },
    methods: {
        hasOneShowingChild(children = [], parent) {
            const showingChildren = children.filter((item) => {
                if (item.hidden) {
                    return false;
                } else {
                    // 暂时设置（仅在只有一个显示的子项时使用）。
                    this.onlyOneChild = item;
                    return true;
                }
            });

            // 如果只有一个子路由要显示，但子路由是索引页面，则显示父路由。
            if (showingChildren.length === 1 && showingChildren[0]?.name =="dashboard") {
                return true;
            }

            // 如果没有子路由需要显示，则显示父路由
            if (showingChildren.length === 0) {
                this.onlyOneChild = { ...parent, path: "", noShowingChildren: true };
                return true;
            }

            return false;
        },
        resolvePath(routePath) {
            if (isExternal(routePath)) {
                return routePath;
            }
            if (isExternal(this.basePath)) {
                return this.basePath;
            }
            return path.resolve(this.basePath, routePath);
        },
    },
};
</script>

<style lang="less" scoped>
.m-nav-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.m-nav-item__count {
    margin-left: auto;
    font-size: 12px;
    color: #fff;
    background-color: #f56c6c;
    border-radius: 3px;
    padding: 0 6px;
    line-height: 20px;
    text-align: center;
    display: inline-block;
    min-width: 20px;
    height: 20px;
    box-sizing: border-box;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    position: relative;
}
</style>
