<template>
    <a href="/" class="c-header-logo">
        <i class="u-logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" v-bind:svg-inline="''" v-bind:fill="'#fff'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M126.6 239.5c-49.5-.1-99-33.1-114.1-87.8-15.3-55.2 10.4-113.2 61.4-139C125-13.1 186.9.5 222.4 45.8c34.3 43.7 30.4 98.8 9.6 133.3-16.6-9.8-33.1-19.6-49.7-29.4 10-20 10.6-40 0-59.7-8.3-15.5-21.4-25.6-38.4-30.1-34.2-9.1-69.1 12.4-76.2 46.9-3.9 18.8 0 36.1 12 51.2 11.9 15.1 27.8 22.7 47.1 23.6-.2 19.3-.2 38.5-.2 57.9z" fill="#144a90"/><path d="M131.4 123.3c3.4 3.2 6.9 6.4 10.3 9.6l32.1 29.7c11 10.2 21.9 20.3 32.9 30.4 6.8 6.3 13.7 12.7 20.5 19-2.7 2.6-5.3 5.2-8 7.8-28.3-30.5-56.7-61.3-85.1-92l-.3.3c25.4 33.1 50.7 66.2 76.2 99.4-3 2.2-6 4.3-9.1 6.5l-68.4-105.3c-.1 0-.1.1-.2.1 19.3 37.1 38.6 74.2 58 111.5-3.3 1.6-6.6 3.2-10.1 4.8-16.3-38.4-32.7-76.7-49-115.1-.1 0-.2.1-.3.1 12.5 39.9 25 79.7 37.6 119.7-3.6 1-7.1 2-10.8 3-9.6-41.7-19.2-83.3-28.8-125-.1 0-.2 0-.4.1 5.5 42.3 11.1 84.5 16.6 127-3.7.4-7.3.7-11.1 1.1-2.4-45.4-4.8-90.7-7.2-136 .9-.4 1.7-.1 2.4.4 14.3 9.2 28.6 18.4 42.9 27.7 12.6 8.1 25.2 16.3 37.9 24.4 10.4 6.7 20.8 13.4 31.1 20.1.2.1.4.3.7.5-2.1 3-4.2 6-6.3 9.2-1.3-1-2.6-2-3.9-2.9-8.5-6.5-17-12.9-25.5-19.4-13.3-10.1-26.5-20.2-39.8-30.3-11.4-8.6-22.7-17.3-34-25.9-.2-.2-.6-.3-.9-.5-.1 0-.1 0-.2-.1 0 0 .1.1.2.1z" fill="#eb6a0a"/></svg>
        </i>
        <span class="u-title">{{ Title }}</span>
    </a>
</template>

<script>
import { Title } from "@/settings.js";
export default {
    name: "HeaderLogo",
    props: [],
    components: {},
    data: function () {
        return {
            Title,
        };
    },
    computed: {},
    watch: {},
    methods: {},
    created: function () {},
    mounted: function () {},
};
</script>

<style scoped lang="less">
.c-header-logo {
    height: @header-height;
    padding: 5px 20px 5px 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-right: 20px;
    .u-logo {
        display: block;
        margin-right: 8px;
        svg,
        img {
            max-height: 100%;
            width: 38px;
        }
    }
    .u-title {
        color: #fff;
    }
    &:hover {
        .u-title {
            color: @lightblue;
        }
        .u-logo {
            svg,
            img {
                fill: @lightblue;
            }
        }
    }
    position: relative;
    &:after {
        content: "";
        position: absolute;
        left: 100%;
        top: 20px;
        bottom: 20px;
        width: 1px;
        background-color: rgba(124, 135, 142, 0.5);
    }
}
@media screen and (max-width: @phone) {
    .c-header-logo:after {
        display: none;
    }
}
</style>
