<template>
    <div class="c-header-msg">
        <router-link class="u-msg" :to="{ name: 'account-message' }">
            <i class="u-icon u-icon-msg">
                <i class="u-pop" style="display: none" v-show="unReadCount"></i>
                <svg height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg" tabindex="-1" v-bind:class="'u-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M27.943 22.07l-.601-1a8.354 8.354 0 01-1.191-4.298v-5.52C26.151 5.654 21.598 1.1 16 1.1S5.849 5.654 5.849 11.251v5.52c0 1.514-.412 3-1.191 4.298l-.601 1.001a2.556 2.556 0 00-.033 2.603 2.555 2.555 0 002.249 1.311h19.455c.943 0 1.784-.49 2.249-1.311a2.558 2.558 0 00-.034-2.603zm-1.533 1.716a.77.77 0 01-.682.398H6.272a.768.768 0 01-.682-.398.768.768 0 01.01-.789l.601-1a10.154 10.154 0 001.448-5.225v-5.52C7.648 6.646 11.395 2.9 16 2.9s8.352 3.747 8.352 8.352v5.52c0 1.84.5 3.647 1.448 5.225l.601 1a.77.77 0 01.009.789zm-7.323 3.972c-.657.84-1.811 1.342-3.087 1.342s-2.43-.502-3.087-1.342a.901.901 0 00-1.418 1.11C12.49 30.14 14.175 30.9 16 30.9s3.51-.76 4.505-2.033a.901.901 0 00-.154-1.264.903.903 0 00-1.264.155z"/></svg>
            </i>
        </router-link>
    </div>
</template>

<script>
import User from "@/utils/user";
export default {
    name: "HeaderMessage",
    data: function () {
        return {};
    },

    computed: {
        unReadCount() {
            return this.$store.state.app.unReadCount ? true : false;
        },
    },
    mounted() {
        User.isLogin() && this.$store.dispatch("app/loadMessageCount");
    },
};
</script>

<style lang="less">
//消息面板
.c-header-msg {
    .pr;
    .pointer;
    height: 100%;

    .u-msg {
        .flex;
        .h(100%);
        align-items: center;
        padding: 0 5px;
        &:hover {
            filter: brightness(110%);
        }
    }
    .u-icon-msg {
        .pr;
        .db;
        .size(17px);
        top: 1px;
        svg {
            .full;
            fill: #fff;
        }
    }
    .u-pop {
        .pa;
        .pointer;
        .size(10px);
        .r(50%);
        .rt(-5px,-6px);
        color: #fff;
        background-image: linear-gradient(#54a3ff, #006eed);
        background-clip: padding-box;
        border: 2px solid #24292e;
    }
}

@media screen and (max-width: @ipad) {
    .c-header-msg {
        display: none;
    }
}
</style>
