import Layout from "@/layouts/default.vue";

export default [
    {
        path: "/system",
        name: "system",
        meta: {
            title: "系统配置",
            icon: "conf",
        },
        component: Layout,
        redirect: "/system/system-conf-list",
        children: [
            {
                path: "",
                name: "system-conf-list",
                component: () => import("@/views/system/global-conf.vue"),
                meta: {
                    title: "全局配置",
                    icon: "conf",
                    permission: "manage_system_config",
                },
            },
            {
                path: "template",
                name: "email-template",
                component: () => import("@/views/notification/template/email/index.vue"),
                meta: {
                    title: "邮件模板",
                    icon: "msg-setting",
                    permission: "manage_email_template",
                },
            },
            {
                path: "template/detail",
                name: "email-template-detail",
                hidden: true,
                component: () => import("@/views/notification/template/email/detail.vue"),
                meta: {
                    title: "邮件模板",
                    icon: "msg-setting",
                    permission: "manage_email_template",
                },
            },
            {
                path: "wechat/menu",
                name: "wechat-menu",
                component: () => import("@/views/system/wx-menu.vue"),
                meta: {
                    title: "微信菜单",
                    icon: "wx",
                    permission: "manage_wxmenu",
                },
            }
        ],
    },
];
