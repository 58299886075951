import Layout from "@/layouts/default.vue";

export default [
    {
        path: "/production",
        name: "production",
        component: Layout,
        redirect: '/production/index',
        meta: {
            title: "产品管理",
            icon: "product",
        },
        children: [
            {
                path: "index",
                name: "production-index",
                component: () => import("@/views/production/index.vue"),
                meta: {
                    title: "型号配置",
                    icon: "model",
                    permission: "manage_needs_production"
                },
            },
            {
                path: "stock",
                name: "production-stock",
                component: () => import("@/views/stock/index.vue"),
                meta: {
                    title: "换货管理",
                    icon: "exchange",
                    permission: "manage_needs_stock"
                }
            },
            {
                path: "/stock/:id",
                name: "production-stock-detail",
                component: () => import("@/views/stock/detail.vue"),
                meta: {
                    title: "换货记录",
                    icon: "test",
                    activeMenu: '/production/stock'
                },
                hidden: true,
            }
        ]
    },
];
