import Layout from "@/layouts/default.vue";

export default [
    {
        path: "/request",
        name: "request",
        component: Layout,
        redirect: '/request/backup',
        meta: {
            title: "申请管理",
            icon: "3d",
            permission: "manage_needs_request"
        },
        children: [
            {
                path: "backup",
                name: "request-backup",
                component: () => import("@/views/request/backup.vue"),
                meta: {
                    title: "备机申请",
                    icon: "backup",
                    keepAlive: true,
                    type : 1,
                },
            },
            {
                path: "accessory",
                name: "request-accessory",
                component: () => import("@/views/request/accessory.vue"),
                meta: {
                    title: "配件申请",
                    icon: "accessory",
                    keepAlive: true,
                    type : 2,
                },
            },
            {
                path: "/request/backup/:id",
                name: "request-detail-backup",
                component: () => import("@/views/request/detail.vue"),
                meta: {
                    title: "申请详情",
                    icon: "test",
                    activeMenu: "/request/backup",
                },
                hidden: true,
            },
            {
                path: "/request/accessory/:id",
                name: "request-detail-accessory",
                component: () => import("@/views/request/detail.vue"),
                meta: {
                    title: "申请详情",
                    icon: "test",
                    activeMenu: "/request/accessory",
                },
                hidden: true,
            },
        ]
    },
];
