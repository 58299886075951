import { $cms } from "@/utils/https"

// ====================工单类型====================

/**
 * 创建工单类型
 * @param {*} data
 * @returns
 */
export const createWorkflowType = (data) => {
    return $cms().post(`/api/cms/workflow/type`, data);
}

/**
 * 删除工单类型
 * @param {*} id
 * @returns
 */
export const deleteWorkflowType = (id) => {
    return $cms().delete(`/api/cms/workflow/type/${id}`);
}

/**
 * 更新工单类型
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const updateWorkflowType = (id, data) => {
    return $cms().put(`/api/cms/workflow/type/${id}`, data);
}

/**
 * 查询工单列表
 * @param {*} params
 * @returns
 */
export const getWorkflowTypeList = (params) => {
    return $cms().get(`/api/cms/workflow/type`, {
        params,
    });
}

/**
 * 查询工单类型详情
 * @param {*} id
 * @returns
 */
export const getWorkflowTypeDetail = (id) => {
    return $cms().get(`/api/cms/workflow/type/${id}`);
}


// ====================工单====================
/**
 * 创建工单
 * @param {*} data
 * @returns
 */
export const createWorkflow = (data) => {
    return $cms().post(`/api/cms/workflow/bucket`, data);
}

/**
 * 删除工单
 * @param {*} id
 * @returns
 */
export const deleteWorkflow = (id) => {
    return $cms().delete(`/api/cms/workflow/bucket/${id}`);
}

/**
 * 更新工单
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const updateWorkflow = (id, data) => {
    return $cms().put(`/api/cms/workflow/bucket/${id}`, data);
}

/**
 * 指派工单
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const assignWorkflow = (id, data) => {
    return $cms().put(`/api/cms/workflow/bucket/${id}/assign`, data);
}

/**
 * 更新描述
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const updateWorkflowDesc = (id, data) => {
    return $cms().put(`/api/cms/workflow/bucket/${id}/desc`, data);
}

/**
 * 查询工单详情
 * @param {*} id
 * @returns
 */
export const getWorkflowDetail = (id) => {
    return $cms().get(`/api/cms/workflow/bucket/${id}`);
}

/**
 * 关闭工单
 *
 * @param {*} id
 * @returns
 */
export const closeWorkflow = (id) => {
    return $cms().put(`/api/cms/workflow/bucket/${id}/close`);
}

/**
 * 查询工单列表
 * @param {*} params
 * @returns
 */
export const getWorkflowList = (params) => {
    return $cms().get(`/api/cms/workflow/bucket`, {
        params,
    });
}

/**
 * 查询待处理工单列表
 * @param {*} params
 * @returns
 */
export const getPendingWorkflowList = (params) => {
    return $cms().get(`/api/cms/workflow/bucket/pending`, {
        params,
    });
}

/**
 * 统计待处理工单
 * @returns
 */
export const getPendingWorkflowCount = () => {
    return $cms().get(`/api/cms/workflow/bucket/pending/count`);
}

/**
 * 查询我创建的工单列表
 *
 * @param {*} params
 * @returns
 */
export const getMyCreateWorkflowList = (params) => {
    return $cms().get(`/api/cms/workflow/bucket/i`, {
        params,
    });
}

/**
 * 统计工单
 * @param {*} params
 * @returns
 */
export const getWorkflowCount = (params) => {
    return $cms().get(`/api/cms/workflow/bucket/count`, {
        params,
    });
}

// ====================工单流程====================
/**
 * 创建工单流程
 * @param {*} data
 * @returns
 */
export const createWorkflowProcess = (data) => {
    return $cms().post(`/api/cms/workflow/process`, data);
}

/**
 * 删除工单流程
 * @param {*} id
 * @returns
 */
export const deleteWorkflowProcess = (id) => {
    return $cms().delete(`/api/cms/workflow/process/${id}`);
}

/**
 * 更新工单流程
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const updateWorkflowProcess = (id, data) => {
    return $cms().put(`/api/cms/workflow/process/${id}`, data);
}

/**
 * 查询工单流程详情
 * @param {*} id
 * @returns
 */
export const getWorkflowProcessDetail = (id) => {
    return $cms().get(`/api/cms/workflow/process/${id}`);
}

/**
 * 查询工单流程列表
 * @param {*} params
 * @returns
 */
export const getWorkflowProcessList = (params) => {
    return $cms().get(`/api/cms/workflow/process`, {
        params,
    });
}

// ====================工单进度====================
/**
 * 创建工单进度
 * @param {*} data
 * @returns
 */
export const createWorkflowProgress = (data) => {
    return $cms().post(`/api/cms/workflow/progress`, data);
}

/**
 * 删除工单进度
 * @param {*} id
 * @returns
 */
export const deleteWorkflowProgress = (id) => {
    return $cms().delete(`/api/cms/workflow/progress/${id}`);
}

/**
 * 更新工单进度
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const updateWorkflowProgress = (id, data) => {
    return $cms().put(`/api/cms/workflow/progress/${id}`, data);
}

/**
 * 查询工单进度详情
 * @param {*} id
 * @returns
 */
export const getWorkflowProgressDetail = (id) => {
    return $cms().get(`/api/cms/workflow/progress/${id}`);
}

/**
 * 查询工单进度列表
 * @param {*} params
 * @returns
 */
export const getWorkflowProgressList = (params) => {
    return $cms().get(`/api/cms/workflow/progress`, {
        params,
    });
}
