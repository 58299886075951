/*=========================================================*/
// hidden 如果设置为true，则不会显示在tabs菜单中，侧边栏也不会展示
// affix 如果设置为true，则标签不可关闭
/*=========================================================*/

// 1.Dependency
import {
    createRouter,
    // createWebHistory,
    createWebHashHistory,
} from "vue-router";
import Layout from "@/layouts/default.vue";
import User from "@/utils/user.js";
import { getPageTitle } from "@/utils/common";
import store from "../store";
const DEV = process.env.NODE_ENV === "development";

// 2.Routes
import productionRoutes from "./production";
import requestRoutes from "./request";
import repairRoutes from "./repair";
import systemRoutes from "./system";
import accountRoutes from "./account";
import userRoutes from "./user";
import workflow from "./workflow";
import deviceRoutes from "./device";
import notifyRoutes from "./notification";
import erpRoutes from "./erp";
import adminRoutes from "./admin";
export const constantRoutes = [
    {
        path: "/__redirect",
        name: "__redirect__",
        component: Layout,
        hidden: true, // 如果设置为true，则不会显示在菜单中
        children: [
            {
                path: "/__redirect/:path(.*)",
                component: () => import("@/views/_/index.vue"),
            },
        ],
    },
    {
        path: "/",
        name: "__root__",
        redirect: "/dashboard", // 重定向首页
        component: Layout,
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                component: () => import("@/views/index/default.vue"),
                meta: {
                    title: "控制台",
                    icon: "dashboard",
                    affix: true,
                    keepAlive: true,
                },
            },
            {
                path: "/export/:type",
                name: "export",
                component: () => import("@/views/record-export.vue"),
                hidden: true,
                meta: {
                    title: "导出记录",
                    icon: "export",
                },
            },
        ],
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/account/login.vue"),
        hidden: true,
        meta: {
            isPublic: true,
        },
    },
    ...productionRoutes,
    ...workflow,
    ...requestRoutes,
    ...repairRoutes,
    ...deviceRoutes,
    ...notifyRoutes,
    ...userRoutes,
    ...accountRoutes,
    ...erpRoutes,
    ...adminRoutes,
    ...systemRoutes,
];

// 3.Permission
export const asyncRoutes = [];

// 4.Build An Instance
const router = createRouter({
    history: createWebHashHistory(), //hash
    // history: createWebHistory(),  //history api
    // base : '/rewrite root',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
});

let loadPermission = true;

router.beforeEach(async (to, from, next) => {
    document.title = getPageTitle(to.meta.title);

    const isLogin = User.isLogin();
    if (isLogin && loadPermission) {
        loadPermission = false;
        await store.dispatch("app/loadMyPermission");
    }

    if (to.path === "/login") {
        next();
    } else if (!isLogin && !to.meta.isPublic && !DEV) {
        next({ path: `/login?redirect=${to.path}` }); // 未登录且非公开页面，跳转到登录
    } else if (hasPermission(to)) {
        store.commit("permission/SET_ACTIVE_MENU", to?.meta?.parent);
        await store.dispatch("permission/generateRoutes", User.getGroup());

        next(); // 有权限，继续导航
    } else {
        next(false); // 无权限，提示无权限
    }
});

// 检查权限
function hasPermission(to) {
    const isSuperAdmin = User.isSuperAdmin();
    const myPermission = User.getPermission() || [];
    const metaGroup = to.meta.group;
    const metaPermission = to.meta.permission || "";
    const myGroup = User.getGroup();

    // 如果是公开页面，可访问
    if (to.meta.isPublic) return true;
    // 如果meta没有设置role 和 permission 正常显示
    if (!metaGroup && !metaPermission) return true;
    // 如果是超级管理员，可访问
    if (isSuperAdmin) return true;
    // 如果有设置页面权限且我的权限组大于等于页面权限组，可访问
    if (metaGroup && myGroup >= metaGroup) return true;
    // 如果设置权限且我的权限列表中有该权限，可访问
    if (metaPermission && myPermission.some((item) => item.action === metaPermission)) return true;

    return false;
}

export default router;
