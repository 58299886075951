import { $cms } from "@/utils/https";

/**
 * 申请列表
 * @param {Object} params 参数
 */
function listRequest(params) {
    return $cms().get(`/api/cms/needs/manage-requests`, { params });
}

/**
 * 申请详情
 * @param {String} id 申请id
 */
function detailRequest(id) {
    return $cms().get(`/api/cms/needs/manage-request/${id}`);
}

/**
 * 删除申请
 * @param {String} id 申请id
 */
function deleteRequest(id) {
    return $cms().delete(`/api/cms/needs/request/${id}`);
}

/**
 * 编辑申请
 * @param {*} id
 * @param {Object} data 申请数据
 */
function editRequest(id, data) {
    return $cms().put(`/api/cms/needs/manage-request/${id}`, data);
}

/**
 * 更新跟进状态
 * @param {*} id
 * @param {Object} data 申请数据
 */
function updateRequestFollowStatus(id, data) {
    return $cms().put(`/api/cms/needs/manage-request/${id}/follow`, data);
}

/**
 * 拒绝申请
 * @param {*} id
 * @param {Object} data 申请数据
 */
function refuseRequest(id, data) {
    return $cms().put(`/api/cms/needs/refuse-request/${id}`, data);
}

/**
 * 确认返回
 * @param {*} id
 * @param {Object} data 申请数据
 */
function confirmReturn(id, data) {
    return $cms().put(`/api/cms/needs/confirm-return/${id}`, data);
}

/**
 * 系统挂账
 * @param {*} id
 * @param {Object} data 申请数据
 */
function creditRequest(id, data) {
    return $cms().put(`/api/cms/needs/credit/${id}`, data);
}

/**
 * 系统销账
 * @param {*} id
 * @param {Object} data 申请数据
 */
function writeoffRequest(id, data) {
    return $cms().put(`/api/cms/needs/write-off/${id}`, data);
}

/**
 * 获取状态流
 * @param {*} type 类型 backup-备机 accessory-配件
 * @param {*} id
 */
function getStatusFlow(type, id) {
    return $cms().get(`/api/cms/needs/manage-request/${type}/${id}/status`);
}

/**
 * 统计
 */
function countRequest() {
    return $cms().get(`/api/cms/needs/manage-requests/count`);
}

export {
    listRequest,
    detailRequest,
    deleteRequest,
    editRequest,
    refuseRequest,
    confirmReturn,
    getStatusFlow,
    updateRequestFollowStatus,
    creditRequest,
    writeoffRequest,
    countRequest,
}
