import Layout from "@/layouts/default.vue";

export default [
    {
        path: "/erp",
        name: "erp",
        component: Layout,
        redirect: "/erp/company",
        meta: {
            title: "企业管理",
            icon: "erp",
        },
        children: [
            {
                path: "relation",
                name: "erp-relation",
                component: () => import("@/views/erp/relation/index.vue"),
                meta: {
                    title: "公司管理",
                    icon: "relation",
                    keepAlive: true,
                    permission: "manage_erp_relation",
                },
                hidden: true,
            },
            {
                path: "purchase",
                name: "erp-purchase",
                component: () => import("@/views/erp/purchase/index.vue"),
                meta: {
                    title: "订单管理",
                    icon: "purchase",
                    keepAlive: true,
                    permission: "manage_erp_purchase",
                },
            },
            {
                path: "purchase/:id",
                name: "erp-purchase-detail",
                component: () => import("@/views/erp/purchase/detail.vue"),
                meta: {
                    title: "订单详情",
                    icon: "purchase",
                    activeMenu: "/erp/purchase",
                    keepAlive: true,
                    permission: "manage_erp_purchase",
                },
                hidden: true,
            },
            {
                path: "stock",
                name: "erp-stock",
                component: () => import("@/views/erp/stock/index.vue"),
                meta: {
                    title: "库存管理",
                    icon: "stock2",
                    keepAlive: true,
                    permission: "get_erp_stock",
                },
            },
            {
                path: "company",
                name: "erp-company",
                component: () => import("@/views/erp/company/index.vue"),
                meta: {
                    title: "客户管理",
                    icon: "company",
                    keepAlive: true,
                    permission: "manage_erp_company",
                },
            },
            {
                path: "group",
                name: "erp-group",
                component: () => import("@/views/erp/group/index.vue"),
                meta: {
                    title: "客户分组",
                    icon: "group-mng",
                    keepAlive: true,
                    permission: "manage_erp_group",
                },
            },
            {
                path: "/company/purchase/:id",
                name: "erp-company-purchase",
                component: () => import("@/views/erp/company/purchase.vue"),
                meta: {
                    title: "公司订单",
                    icon: "company",
                    activeMenu: "/erp/company",
                    keepAlive: true,
                    permission: "get_erp_company",
                },
                hidden: true,
            },
        ],
    },
];
