import Layout from "@/layouts/default.vue";

export default [
    {
        path: "/repair",
        name: "repair",
        component: Layout,
        redirect: '/repair/list',
        meta: {
            title: "返修管理",
            icon: "repair-manage",
            permission: "manage_needs_repair"
        },
        children: [
            {
                path: "list",
                name: "repair-list",
                component: () => import("@/views/repair/index.vue"),
                meta: {
                    title: "普通返修",
                    icon: "repair",
                    keepAlive: true,
                },
            },
            {
                path: "/repair/:id",
                name: "repair-detail",
                component: () => import("@/views/repair/detail.vue"),
                meta: {
                    title: "返修详情",
                    icon: "test",
                    activeMenu: "/repair/list",
                },
                hidden: true,
            },
            {
                path: "quickrp",
                name: "repair-quickrp",
                component: () => import("@/views/repair/quickrp.vue"),
                meta: {
                    title: "快速返修",
                    icon: "quickrp",
                    keepAlive: true,
                    type : 3,
                },
            },
            {
                path: "/quickrp/:id",
                name: "quickrp-detail",
                component: () => import("@/views/repair/quickrp-detail.vue"),
                meta: {
                    title: "快速返修详情",
                    icon: "quickrp",
                    activeMenu: "/repair/quickrp",
                },
                hidden: true,
            }
        ]
    },
]
