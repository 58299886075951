import Layout from "@/layouts/default.vue";

export default [
    {
        path: "/account",
        name: "account",
        component: Layout,
        redirect: '/account/profile',
        meta: {
            title: "个人中心",
            icon: "account-admin",
        },
        hidden: true,
        children: [
            {
                path: "profile",
                name: "account-profile",
                component: () => import("@/views/account/profile.vue"),
                meta: {
                    title: "资料设置",
                    icon: "account",
                },
                hidden: true,
            },
            {
                path: "message",
                name: "account-message",
                component: () => import("@/views/account/message.vue"),
                meta: {
                    title: "消息",
                    icon: "account",
                },
                hidden: true,
            },
        ]
    }
]
