// 获取文档标题
const { Title, CDN } = require("@/settings");
export function getPageTitle(pageTitle) {
    if (pageTitle) {
        return `${pageTitle} - ${Title}`;
    }
    return Title || "Admin";
}

export function getCdnLink(url) {
    return CDN + '/' + url
}

export function getThumbnail(url, size = 88) {
    if (!url) return "";

    url = url.replace(/http:/g, "https:");

    if (Array.isArray(size)) {
        url += `?x-oss-process=image/auto-orient,1/resize,m_fill,w_${size[0]},h_${size[1]}/quality,Q_100`;
    } else if (isNaN(size)) {
        url += `?x-oss-process=style/${size}`;
    } else {
        url += `?x-oss-process=image/auto-orient,1/resize,m_fill,w_${size},h_${size}/quality,Q_100`;
    }

    return url;
}
