<template>
    <div class="c-header-notice">
        <a class="u-home" href="http://www.suplasercut.com/" target="_blank"><el-icon><HomeFilled /></el-icon><span>官网</span></a>
    </div>
</template>

<script>
export default {
    name: "HeaderNotice",
    props: [],
    components: {},
    data: function () {
        return {};
    },
    computed: {},
    watch: {},
    methods: {},
    created: function () {},
    mounted: function () {},
};
</script>

<style scoped lang="less">
.c-header-notice {
    color: #c1c6c8;
    font-size: 12px;
    height: @header-height;
    .u-home {
        line-height: @header-height;
        color:#eee;
        &:hover {
            color: @lightblue;
        }
        display: flex;
        align-items: center;
        span{
            .ml(3px);
        }
        i{
            font-size: 16px;
        }
    }

    margin-right: 20px;
    padding-right: 20px;

    position: relative;
    // &:after {
    //     content: "";
    //     position: absolute;
    //     left: 100%;
    //     top: 20px;
    //     bottom: 20px;
    //     width: 1px;
    //     background-color: rgba(124, 135, 142, 0.5);
    // }
}
@media screen and (max-width: @phone) {
    .c-header-notice {
        display: none;
    }
}
</style>
