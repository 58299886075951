import Layout from "@/layouts/default.vue";

export default [
    {
        path: "/admin",
        name: "admin",
        meta: {
            title: "后台管理",
            icon: "system",
        },
        component: Layout,
        redirect: "/admin/list",
        children: [
            {
                path: "list",
                name: "account-list",
                component: () => import("@/views/account/list.vue"),
                meta: {
                    title: "系统账号",
                    icon: "account",
                    permission: "manage_admin_account",
                },
            },
            {
                path: "permission",
                name: "account-permission",
                component: () => import("@/views/account/permission.vue"),
                meta: {
                    title: "权限设置",
                    icon: "lock",
                    permission: "manage_account_permission",
                },
            },
            {
                path: "action-log",
                name: "action-log",
                component: () => import("@/views/system/action-log.vue"),
                meta: {
                    title: "管理日志",
                    icon: "log",
                    permission: "get_action_log",
                },
            },
        ],
    },
];
