import { $cms } from "@/utils/https";

/**
 * 返修需求列表
 * @param {Object} params 参数
 */
function listRepair(params) {
    return $cms().get(`/api/cms/needs/manage-repairs`, { params });
}

/**
 * 获取返修需求详情
 * @param {String} id 需求id
 */
function detailRepair(id) {
    return $cms().get(`/api/cms/needs/manage-repair/${id}`);
}

/**
 * 删除申请
 * @param {String} id 申请id
 */
 function deleteRepair(id) {
    return $cms().delete(`/api/cms/needs/repair/${id}`);
}

/**
 * 编辑返修需求
 * @param {*} id
 * @param {Object} data 需求数据
 */
function editRepair(id, data) {
    return $cms().put(`/api/cms/needs/manage-repair/${id}`, data);
}

/**
 * 更新返修状态
 * @param {*} id
 * @param {Object} data 需求数据
 */
function updateRepairStatus(id, data) {
    return $cms().put(`/api/cms/needs/manage-repair/${id}/status`, data);
}

/**
 * 更新跟进状态
 * @param {*} id
 * @param {Object} data 需求数据
 */
function updateRepairFollowStatus(id, data) {
    return $cms().put(`/api/cms/needs/manage-repair/${id}/follow`, data);
}

/**
 * 返回进度
 * @param {*} id
 */
function getRepairProcess(id){
    return $cms().get(`/api/cms/needs/manage-repair/${id}/status`);
}

/**
 * 统计待处理数量
 */
function countRepair() {
    return $cms().get(`/api/cms/needs/manage-repair/count`);
}


export {
    listRepair,
    detailRepair,
    deleteRepair,
    editRepair,
    updateRepairStatus,
    getRepairProcess,
    updateRepairFollowStatus,
    countRepair
}
