import { $cms } from "@/utils/https.js";

// 获取未读消息
export function getMessageUnRead() {
    return $cms().get(`/api/cms/admin/message/count`);
}
// 获取收件箱列表
export function getInboxMessages(params) {
    return $cms().get(`/api/cms/admin/message/inbox`, { params });
}
// 获取发件箱列表
export function getOutboxMessages(params) {
    return $cms().get(`/api/cms/admin/message/outbox`, { params });
}
// 删除消息
export function delMessage(ids) {
    return $cms().delete(`/api/cms/admin/message`, { params: { id: ids } });
}
// 修改消息
export function updateMessage(id, data) {
    return $cms().put(`/api/cms/admin/message/${id}`, data);
}
// 发送消息
export function sendMessage(data, params) {
    return $cms().post(`/api/cms/admin/message`, data, { params });
}
// 全部已读
export function readAllMessage() {
    return $cms().put(`/api/cms/admin/message/count`);
}
// 用户-信息列表
export function getMessages(params) {
    return $cms().get(`/api/cms/manage/message`, { params });
}
// 用户-更新信息
export function updateUserMessage(id, data) {
    return $cms().put(`/api/cms/manage/message/${id}`, data);
}
// 用户-删除信息
export function deleteMessage(id) {
    return $cms().delete(`/api/cms/manage/message/revoke`, {
        params: {
            id,
        },
    });
}
// 用户-发送信息
export function createUserMessage(id, data) {
    return $cms().post(`/api/cms/manage/message`, data, {
        params: {
            id,
        },
    });
}