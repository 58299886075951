import Layout from "@/layouts/default.vue";

export default [
    {
        path: "/device",
        name: "device",
        component: Layout,
        redirect: '/device/active-log',
        meta: {
            title: "设备管理",
            icon: "account-admin",
        },
        children: [
            {
                path: "active-log",
                name: "active-log",
                component:  () => import("@/views/code/active-log.vue"),
                meta: {
                    title: "解密记录",
                    icon: "key",
                    permission: "get_active_log"
                },
            },
        ]
    }
]
