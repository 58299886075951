/**
 * 统计各个模块的待处理事项
 */

import { countRepair } from "@/service/repair";
import { countRequest } from "@/service/request";
import { getPendingWorkflowCount } from "@/service/workflow"
import store from "@/store";

const useCount = () => {
    // 统计返修数量
    const countRepairNum = async () => {
        await countRepair().then((res) => {
            const data = {
                list: res.data.data?.repair || 0,
                quickrp: res.data.data?.quickrp || 0,
            }

            store.commit("app/SET_COUNTS", { key: "repair", value: Object.values(data).reduce((prev, cur) => prev + cur, 0) });

            Object.keys(data).forEach((key) => {
                store.commit("app/SET_COUNTS", { key: `repair-${key}`, value: data[key] });
            });
        });
    }

    // 统计申请数量
    const countRequestNum = async () => {
        await countRequest().then((res) => {
            const data = res.data.data || {};

            store.commit("app/SET_COUNTS", { key: "request", value: Object.values(data).reduce((prev, cur) => prev + cur, 0) });

            Object.keys(data).forEach((key) => {
                store.commit("app/SET_COUNTS", { key: `request-${key}`, value: data[key] });
            });
        });
    }

    // 统计工单数量
    const countWorkflowNum = async () => {
        await getPendingWorkflowCount().then((res) => {
            const data = res.data.data?.[0] || 0

            store.commit("app/SET_COUNTS", { key: "workflow", value: data });

            store.commit("app/SET_COUNTS", { key: "workflow-consumer", value: data });
        });
    }

    return {
        countRepairNum,
        countRequestNum,
        countWorkflowNum,
    }
}

export default useCount;
