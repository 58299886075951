import Layout from "@/layouts/default.vue";

export default [
    {
        path: "/notification",
        component: Layout,
        redirect: "/notification/message",
        meta: {
            title: "通知中心",
            icon: "letter",
            permission: "manage_message",
        },
        children: [
            {
                path: "message",
                name: "notification-message",
                component: () => import("@/views/notification/message/index.vue"),
                meta: {
                    title: "私信管理",
                    icon: "private",
                },
            },
            {
                path: "email",
                name: "notification-email",
                component: () => import("@/views/notification/email/index.vue"),
                meta: {
                    title: "邮件管理",
                    icon: "email",
                },
            },
            {
                path: "email/detail",
                name: "notification-email-detail",
                hidden: true,
                component: () => import("@/views/notification/email/email-detail.vue"),
                meta: {
                    title: "邮件中心",
                },
            },
            {
                path: "wechat",
                name: "wechat-message",
                component: () => import("@/views/notification/wechat/index.vue"),
                meta: {
                    title: "微信管理",
                    icon: "msg-wechat",
                },
            },
            {
                path: "phmsg",
                name: "omp-msg-phmsg",
                component: () => import("@/views/notification/phmsg/index.vue"),
                meta: {
                    title: "短信管理",
                    icon: "phmsg",
                },
            },
            {
                path: "stat",
                name: "omp-msg-stat",
                component: () => import("@/views/notification/stat/index.vue"),
                meta: {
                    title: "通知统计",
                    icon: "stat",
                },
            },
        ],
    },
];
