import { $cms } from "@/utils/https.js";

function getActionLogs(params) {
    return $cms().get(`/api/cms/system/action-logs`, {
        params,
    });
}

function getUserActionLogs(params) {
    return $cms().get(`/api/cms/system/log/user`, {
        params,
    });
}

function upload(formData) {
    return $cms().post(`/api/cms/system/upload`, formData);
}

function addConf(data) {
    return $cms().post(`/api/cms/system/config`, data);
}

function getConf(params) {
    return $cms().get(`/api/cms/system/config`, {
        params,
    });
}

function updateConf(id, data) {
    return $cms().put(`/api/cms/system/config/${id}`, data);
}

function delConf(id) {
    return $cms().delete(`/api/cms/system/config/${id}`);
}

function getConfByKey(key) {
    return $cms().get(`/api/cms/system/config/key/${key}`);
}

// 获取全部微信菜单
function getAllWechatMenu() {
    return $cms().get(`/api/cms/manage/wechat/mp/menu/all`);
}

// 获取自定义微信菜单
function getWechatMenu() {
    return $cms().get(`/api/cms/manage/wechat/mp/menu`);
}

// 创建自定义微信菜单
function createWechatMenu(data) {
    return $cms().post(`/api/cms/manage/wechat/mp/menu`, data);
}
// 删除自定义微信菜单
function delWechatMenu() {
    return $cms().delete(`/api/cms/manage/wechat/mp/menu`);
}

export {
    getActionLogs,
    getUserActionLogs,
    upload,
    addConf,
    getConf,
    updateConf,
    delConf,
    getConfByKey,
    getAllWechatMenu,
    getWechatMenu,
    createWechatMenu,
    delWechatMenu,
};
