<template>
    <div>
        <div class="c-user" ref="userRef">
            <img
                @click.stop="copyName(data)"
                title="复制"
                class="u-avatar"
                v-if="data?.name || !data?.wechat_name || data?.wechat_name !== '微信用户'"
                :src="avatar"
                :alt="data?.wechat_name"
            />
            <span class="u-name">{{ name }}</span>
            <!-- <template v-if="wechat">
            <span class="u-name" v-if="!data.wechat_name || data.wechat_name !== '微信用户'">&nbsp;({{ data.wechat_name }})</span>
        </template> -->
        </div>

        <el-popover
            ref="popoverRef"
            :virtual-ref="userRef"
            trigger="hover"
            virtual-triggering
            placement="top"
            popper-class="c-user-popover"
            v-if="showPopover"
        >
            <div class="c-user-popover__main">
                <div class="c-user-main">
                    <div class="u-left">
                        <img :src="avatar" class="u-user-avatar" alt="" />
                    </div>
                    <div class="u-right">
                        <div class="u-username">{{ name }}</div>

                        <div class="u-company u-meta" v-if="isCustom">
                            <span class="u-label">集成商</span>
                            <span class="u-value">{{ data?.company || "-" }}</span>
                        </div>
                        <div class="u-meta" v-else-if="!isCustom && data?.group">
                            <span class="u-label">用户组</span>
                            <span class="u-value">{{ group?.label || "普通成员" }}</span>
                        </div>
                    </div>
                </div>

                <div class="c-user-extra">
                    <div class="u-meta" v-if="isCustom">
                        <span class="u-label"
                            ><el-icon><ChatRound /></el-icon
                        ></span>
                        <span class="u-value">{{ data?.wechat_name || "-" }}</span>
                    </div>
                    <div class="u-meta">
                        <span class="u-label"
                            ><el-icon><Iphone /></el-icon
                        ></span>
                        <span class="u-value">{{ data?.phone || "-" }}</span>
                    </div>
                    <div class="u-meta">
                        <span class="u-label"
                            ><el-icon><Message /></el-icon
                        ></span>
                        <span class="u-value">{{ data?.email || "-" }}</span>
                    </div>
                </div>
            </div>
            <div class="c-user-popover__meta"></div>
        </el-popover>
    </div>
</template>

<script setup>
import { ref } from "vue";
const userRef = ref();
const popoverRef = ref();
</script>

<script>
import { copyText, avatarUrl } from "@/utils";
import { getCdnLink, getThumbnail } from "@/utils/common";
import accountGroup from "@/assets/data/account_group.json";
export default {
    name: "CommonUser",
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        isCustom: {
            // 是否为客户用户
            type: Boolean,
            default: true,
        },
        showPopover: {
            type: Boolean,
            default: true,
        },
        showKey: {
            type: String,
            default: "",
        },
    },
    data() {
        return {};
    },
    computed: {
        name({ data }) {
            return this.showKey ? data[this.showKey] : data?.name || data?.nickname || data?.username;
        },
        avatar({ data }) {
            if (data?.wechat_avatar) {
                return avatarUrl(data?.wechat_avatar);
            } else {
                const url = data?.avatar;
                return url ? getThumbnail(getCdnLink(url), 64) : require("@/assets/img/common/logo.svg");
            }
        },
        group() {
            if (this.data?.group) {
                return accountGroup.find((item) => item.value === this.data?.group) || {};
            }
            return {};
        },
    },
    methods: {
        copyName(data) {
            const name = data?.wechat_name && data?.wechat_name !== "微信用户" ? data?.wechat_name : data?.name;
            copyText(name);
        },
    },
};
</script>

<style lang="less">
@user-avatar-size: 40px;
.c-user {
    display: inline-flex;
    align-items: center;
    background: #fafbfc;
    padding: 2px 5px;
    border: 1px solid #eee;
    border-radius: 2px;
    font-size: 12px;
    line-height: 14px;

    .u-name {
        // flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 5px;
    }
    .u-avatar {
        .size(16px);
        border-radius: 50%;
        .pointer;
        .db;
    }
}

.c-user-popover {
    min-width: 200px !important;
    width: unset !important;

    .c-user-popover__meta {
    }

    .u-meta {
        .fz(12px);
        color: #999;
        line-height: 16px;
        padding: 2px 0;
        .flex;
        align-items: center;
    }

    .u-label {
        display: inline-flex;
        align-items: center;
        gap: 3px;
        &::after {
            content: ":";
            margin-right: 4px;
        }
    }

    .u-value {
    }
}

.c-user-main {
    .flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 5px;
    .u-user-avatar {
        .size(@user-avatar-size);
        border-radius: 8px;
        .pointer;
        .db;
    }
    .u-username {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #333;
    }
    .u-company {
        .u-value {
            color: orange;
        }
    }
}

.c-user-extra {
    border-top: 1px solid #eee;
    margin-left: @user-avatar-size + 10px;
    padding: 5px 0;
}
</style>
