import Layout from "@/layouts/default";

export default [
    {
        path: "/user",
        component: Layout,
        redirect: "/user/list",
        meta: {
            title: "客户管理",
            icon: "user",
            permission: "manage_user_account",
        },
        children: [
            {
                path: "list",
                name: "user-list",
                component: () => import("@/views/user"),
                meta: {
                    title: "客户账号",
                    icon: "account",
                },
            },
            {
                // 权限
                path: "permission",
                name: "user-permission",
                component: () => import("@/views/user/permission"),
                meta: {
                    title: "权限配置",
                    icon: "group",
                },
            },
            {
                path: "user-action-log",
                name: "user-action-log",
                component: () => import("@/views/system/user-action-log.vue"),
                meta: {
                    title: "用户日志",
                    icon: "userlog",
                    permission: "get_user_log",
                },
            },
        ],
    },
];
