import { constantRoutes } from "@/router";
import User from "@/utils/user";

/**
 * 使用my_role,my_permissions判断是否与当前route权限匹配
 * @param my_role
 * @param my_permissions
 * @param route
 */
function hasPermission(my_role, my_permissions, route) {
    const { meta, hidden } = route;

    if (hidden) return false;
    if (meta) {
        const { permission, group } = meta;
        if (group) return my_role >= group;
        if (!group && permission && my_role >= 512) return true;
        if (permission) return my_permissions.includes(permission);
        return true;
    }

    return true;
}

/**
 * 通过递归过滤异步路由表
 * @param routes asyncRoutes
 * @param my_role
 * @param my_permissions
 */

export function filterAsyncRoutes(my_role, my_permissions, routes) {
    const res = [];
    routes.forEach((route) => {
        if (hasPermission(my_role, my_permissions, route)) {
            if (route.children) {
                // 递归过滤子路由
                route.children =
                    (route.children.length && filterAsyncRoutes(my_role, my_permissions, route.children)) || [];
                // 只将有子路由的路由添加到结果中
                if (route.children.length) {
                    res.push(route);
                }
            } else {
                res.push(route);
            }
        }
    });
    return res;
}

const state = {
    routes: [],
    addRoutes: [],

    activeMenu: "admin",
    permissions: [],
    hasPermission: true,
};

const mutations = {
    SET_ROUTES: (state, routes) => {
        state.addRoutes = routes;
        state.routes = routes.filter((route) => {
            return (route.meta && route.meta.parent === state.activeMenu) || !route.meta;
        });
    },
    SET_ACTIVE_MENU: (state, activeMenu) => {
        state.activeMenu = activeMenu;
    },
    SET_PERMISSIONS: (state, permissions) => {
        state.permissions = permissions;
    },
    CHANGE_HAS_PERMISSION(state, boolean) {
        state.hasPermission = boolean;
    },
};

const actions = {
    generateRoutes({ commit }, role) {
        return new Promise((resolve) => {
            let accessedRoutes;
            // 如果最高权限，则全部显示，否则进行role过滤
            if (role >= 512) {
                accessedRoutes = constantRoutes || [];
            } else {
                // 判断，如果我的权限有对应的则添加进路由
                const my_permissions = (User.getPermission() && User.getPermission().map((item) => item.action)) || [];
                accessedRoutes = filterAsyncRoutes(role, my_permissions, constantRoutes);
            }
            commit("SET_ROUTES", accessedRoutes);
            resolve(accessedRoutes);
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
